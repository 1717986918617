<template>
  <div>
    <b
      v-if="file.name"
      class="red--text text--darken-4"
    >{{ file.name }}：</b>
    <v-container fluid>
      <v-row>
        <v-col
          v-for="(img, i) in (file.value?file.value:file)"
          :key="i"
          class="d-flex child-flex pa-0 mr-1"
          cols="2"
          style="max-width:100%"
        >
          <v-card
            style="width:100px"
          >
            <template
              v-if="img.suffix === 'png' || img.suffix === 'jpg' || img.suffix === 'jpeg' || img.suffix === 'git' || img.suffix === 'PNG' || img.suffix === 'JPG' || img.suffix === 'JPEG' || img.suffix === 'GIT'"
            >
              <v-img
                style="cursor:pointer"
                :src="img.url"
                :lazy-src="img.url"
                aspect-ratio="1"
                contain
                class="grey lighten-2"
                @click="imageMaxModel(img.sourceUrl, img.oldName)"
              />
            </template>
            <template
              v-else-if="img.suffix === 'pdf' || img.suffix === 'PDF'"
            >
              <v-img
                style="cursor:pointer"
                :src="pdf"
                :lazy-src="img.url"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="downFile(img.url, img.oldName, img.suffix)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </template>
            <template
              v-else-if="img.suffix === 'xls' || img.suffix === 'xlsx' || img.suffix === 'XLS' || img.suffix === 'XLSX'"
            >
              <v-img
                style="cursor:pointer"
                :src="els"
                :lazy-src="img.url"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="downFile(img.url, img.oldName, img.suffix)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </template>
            <template
              v-else-if="img.suffix === 'doc' || img.suffix === 'docx' || img.suffix === 'DOC' || img.suffix === 'DOCX'"
            >
              <v-img
                style="cursor:pointer"
                :src="doc"
                :lazy-src="img.url"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="downFile(img.url, img.oldName, img.suffix)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </template>
            <template
              v-else-if="img.suffix === 'zip' || img.suffix === '7z' || img.suffix === 'rar' || img.suffix === 'ZIP' || img.suffix === '7Z' || img.suffix === 'RAR'"
            >
              <v-img
                style="cursor:pointer"
                :src="zip"
                :lazy-src="img.url"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="downFile(img.url, img.oldName, img.suffix)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </template>
            <template
              v-else-if="img.suffix === 'pptx' || img.suffix === 'ppt' || img.suffix === 'PPTX' || img.suffix === 'PPT'"
            >
              <v-img
                style="cursor:pointer"
                :src="ppt"
                :lazy-src="img.url"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="downFile(img.url, img.oldName, img.suffix)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </template>
            <v-card-title class="text-h6 pa-0 pl-1 pr-1">
              {{ img.oldName }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="imageShow"
      max-width="700"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          {{ photoName }}
        </v-card-title>
        <v-card-text>
          <v-img
            :src="bigPhoto"
            aspect-ratio="1"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="imageShow = false"
          >
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      file: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        imageShow: false,
        bigPhoto: '',
        photoName: '',
        pdf: require('../../../assets/pdf.png'),
        els: require('../../../assets/excel.png'),
        doc: require('../../../assets/word.png'),
        zip: require('../../../assets/zip.png'),
        ppt: require('../../../assets/ppt.png'),
      }
    },
    computed: {
      // files: function () {
      //   return this.file.value.split(',');
      // }
    },
    methods: {
      //! 弹出图片大图
      imageMaxModel (bigPhoto, photoName) {
        this.imageShow = true
        this.bigPhoto = bigPhoto
        this.photoName = photoName
      },

      downFile (url, fileName, suffix) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
    },
  }
</script>

<style>

</style>
